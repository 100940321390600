<template>
  <div class="orderList">
     <div class="title">
        <img src="../../../../src/assets/image/black.png" alt="" @click="goblack">
        <span>选择售后类型</span>
     </div>
    <div class="goods" v-for="(item, index) in orderInfo.sub" :key="index">
        <img :src="item.topPic" alt="">
        <div class="goodinfo">
        <div class="name">{{item.name}}</div>
        <div class="tags"><span>{{item.skuAttr}}</span><span>×{{item.number}}</span></div>
        <div class="price">
            ￥{{item.skuPrice}}
        </div>
        </div>
    </div>
    <!-- 类型选择 -->
    <div class="type_list">
        <div class="type_title">选择售后类型</div>
        <div class="type_nav" @click="goReturns($route.params.orderId)">
            <div class="type_nei">
                <div class="type_img"><img src="../../../../src/assets/image/sales.png" alt=""></div>
                <div class="type_div">
                    <div class="top_text">我要退货</div>
                    <div class="last_text">{{orderInfo.refundEndTime}}前可申请</div>
                </div>
            </div>
            <div class="type_ico">
                <img src="../../../../src/assets/image/right01.png" alt="">
            </div>
        </div>
        <div class="type_nav" @click="goBarter($route.params.orderId)">
            <div class="type_nei">
                <div class="type_img"><img src="../../../../src/assets/image/sales2.png" alt=""></div>
                <div class="type_div">
                    <div class="top_text">我要换货</div>
                    <div class="last_text">{{orderInfo.refundEndTime}}前可申请</div>
                </div>
            </div>
            <div class="type_ico">
                <img src="../../../../src/assets/image/right01.png" alt="">
            </div>
        </div>
    </div>
    <bottomList></bottomList>
  </div>
</template>

<script>
import { getOrderInfo } from '@/api/order'
export default {
  name: "aftermarketType",

  data() {
    return {
      orderInfo: {},
    };
  },

  mounted() {
    if(this.$route.params.orderId) {
        localStorage.setItem('orderId', this.$route.params.orderId)
    } else {
        this.$route.params.orderId = localStorage.getItem('orderId')
    }
    this.getInfo()
  },

  methods: {
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    // 获取订单信息
    getInfo() {
      getOrderInfo(this.$route.params.orderId).then(res => {
        this.orderInfo = res.data
      })
    },
    // 去退款
    goReturns(id) {
        this.$router.push({
            name: `returns`,
            params: {
                orderId: id
            }
        })
    },
    // 去换货
    goBarter(id) {
        this.$router.push({
            name: `barter`,
            params: {
                orderId: id
            }
        })
    },
  },
};
</script>

<style lang="less" scoped>
.orderList{
    width: 100vw;
    height: 100vh;
   background: #F6F7FA;
}
.title{
    width: 100%;
    position: fixed;
    top: 0rem;
    left: 0rem;
    z-index: 11;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
}

.goods{
  margin-top: 60rem;
  display: flex;
  padding: 15rem;
  background: #fff;
  img{
    width: 98rem;
    height: 98rem;
  }
  .goodinfo{
    margin-left: 8rem;
    width: calc(100% - 116rem);
    .name{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #000000;
    }
    .tags{
      display: flex;
      margin-top: 5rem;
      justify-content: space-between;
      span{
          font-family: PingFangSC, PingFang SC;
          font-weight: 300;
          font-size: 13rem;
          color: #939297;
      }
    }
    .price{
      font-family: DINPro, DINPro;
      font-weight: 500;
      font-size: 14rem;
      color: #000000;
      margin-top: 32rem;
    }
  }
}
.type_list{
    width: 100vw;
    background: #FFFFFF;
    margin-top: 10rem;
    .type_title{
        padding-left: 16rem;
        height: 50rem;
        line-height: 50rem;
        font-weight: 400;
        font-size: 14rem;
        color: #969CA8;
    }
}
.type_nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15rem 16rem;
    border-top: 1rem solid #F6F7FA;
    .type_nei{
        display: flex;
        align-items: center;
        .type_img{
            img{
                width: 16rem;
                height: 16rem;
            }
        }
        .type_div{
            margin-left: 11rem;
            .top_text{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 15rem;
                color: #000000;
            }
            .last_text{
                font-family: PingFangSC, PingFang SC;
                font-weight: 300;
                font-size: 13rem;
                color: #7E828D;
                margin-top: 2rem;
            }
        }
    }
    .type_ico{
        img{
            width: 8rem;
            height: 12rem;
        }
    }
}
</style>
